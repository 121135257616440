@import 'account-flagship_tasting_room.scss';
@import './customizeshipment-wineclub.scss';

.your-shipment {
	.heading {
		border-bottom: 1px solid inherit;
		font-size: 24px;
		letter-spacing: 0.23;
		line-height: 32px;
		margin-bottom: 30px;
		padding-bottom: 8px;
	}

	.shipment-card {
		border: 1px solid inherit;
		margin-bottom: 30px;
		padding: 25px;
		padding-top: 0;
		position: relative;

		.shipment-error {
			color: $color-error;
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 16px;
		}

		.process-order-error {
			color: $color-error;
			font-size: 12px;
			line-height: 16px;
			margin-bottom: 16px;
		}

		.skip-modal {
			text-align: center;

			@include media(md-down) {
				margin-top: 80px;
			}

			.skip {
				font-size: 16px;
				letter-spacing: 0.15px;
				line-height: 24px;
				text-transform: uppercase;
			}
		}

		.title-block {
			display: flex;
			font-family: $font-alt;
			justify-content: space-between;
			margin-bottom: 38px;
			margin-top: 61px;

			.title {
				font-size: 24px;
				letter-spacing: 0.23;
				line-height: 32px;
			}

			.skip {
				font-size: 16px;
				letter-spacing: 0.15;
				line-height: 24px;
				text-transform: uppercase;
			}
		}

		.shipment-type {
			background: $color-primary;
			color: $color-primary-background;
			padding: 7px 0 7px 16px;
			position: absolute;
			right: 0;
			width: 100%;

			.title {
				font-family: $font-alt;
				font-size: 14px;
				letter-spacing: 0.13px;
				line-height: 16px;
				text-transform: capitalize;
				width: 100%;

				&::after {
					content: ' |';
					margin: 0 3px;
				}
			}

			&.confirm {
				background-color: $color-shade_3;
				color: $color-primary;

				.title {
					&::after {
						content: '';
					}
				}
			}

			.customize {
				font-size: 14px;
				letter-spacing: 0.13px;
				line-height: 16px;
			}
		}

		.shipment-info {
			display: block;

			@include media(md-up) {
				border-bottom: 1px solid inherit;
				display: flex;
			}

			.shipment-info-col {
				@include media(md-up) {
					display: inline-block;
					padding-right: 50px;
					width: 50%;
				}

				.reschedule-modal-link {
					margin-bottom: 30px;
					text-align: left;

					.b-login_helpers-forgot_link {
						font-size: 16px;
						letter-spacing: 0.15px;
						line-height: 24px;
					}
				}

				.info-list {
					display: flex;
					justify-content: space-between;
					margin-bottom: 16px;

					&:nth-child(n-1) {
						margin-bottom: 16px;
					}

					.info-title {
						font-family: $font-alt;
						font-size: 16px;
						letter-spacing: 0.15;
						line-height: 24px;
					}

					.info-amt {
						font-family: $font-alt;
						font-size: 16px;
						letter-spacing: 0.15;
						line-height: 24px;
					}

					&.total {
						border-top: 1px solid inherit;
						font-family: $font-alt;
						font-weight: bold;
						padding-top: 16px;
					}
				}

				.discount {
					color: inherit;
				}

				.info-shipmentDate {
					font-family: $font-alt;
					font-size: 20px;
					letter-spacing: 0.19px;
					line-height: 24px;
					margin-bottom: 0;
					margin-top: 25px;

					.info-amt.shipment-date-info {
						font-weight: bold;
					}
				}

				.shipping-info {
					display: block;
					flex-direction: column;
					margin-bottom: 40px;

					@include media(md-up) {
						display: inline-flex;
						flex-direction: column;
						margin-bottom: 40px;
						margin-right: 20px;
						width: 42%;
					}

					.title {
						display: block;
						font-family: $font-main;
						font-size: 16px;
						font-weight: bold;
						letter-spacing: 0.15;
						line-height: 24px;
						margin-bottom: 16px;
					}

					.description {
						display: block;
						font-family: $font-alt;
						font-size: 16px;
						letter-spacing: 0.15px;
						line-height: 24px;
						word-wrap: break-word;

						@include media(sm) {
							padding-bottom: 8px;
						}

						p {
							margin-bottom: 0;
						}

						&.info {
							color: inherit;
							font-size: 14px;
							letter-spacing: 0.13;
							line-height: 16px;
						}
					}

					a {
						color: $color-link;
						margin-top: 16px;
						padding-bottom: 3px;
						text-transform: uppercase;
						width: fit-content;
					}
				}
			}
		}

		.shipping-products {
			.shipping-header {
				justify-content: space-between;
				margin: 24px 0;

				@include media(md-up) {
					display: flex;
				}

				.title {
					font-size: 16px;
					font-weight: bold;
					letter-spacing: 0.15;
					line-height: 24px;
					text-transform: capitalize;

					@include media(md-up) {
						display: flex;
					}
				}

				.b-account_card-button.customizecard {
					@include media(sm) {
						bottom: 80px;
						left: 18px;
						padding: 10px 0px;
						position: absolute;
						width: 90%;
					}
				}
			}

			.shipping-items {
				.item {
					display: inline-flex;
					flex-direction: column;
					margin-bottom: 24px;

					@include media(md-up) {
						width: 49%;
					}

					.item-container {
						display: flex;
						justify-content: start;

						.product-image {
							height: 104px;
							margin-right: 24px;
							max-width: 56px;

							img {
								height: auto;
								min-height: 100%;
								min-width: 100%;
								object-fit: cover;
								width: 100%;
							}
						}

						.product-desc {
							.title {
								font-size: 16px;
								font-weight: bold;
								letter-spacing: 0.15;
								line-height: 24px;
								margin-bottom: 16px;
								text-transform: capitalize;
							}

							.price {
								font-family: $font-alt;
								font-size: 16px;
								letter-spacing: 0.15;
								line-height: 21px;
								margin-bottom: 12px;

								.m-old {
									margin-right: 15px;
									text-decoration: line-through;
								}
							}

							.qty {
								font-size: 16px;
								letter-spacing: 0.15;
								line-height: 21px;
								text-transform: uppercase;
							}
						}
					}
				}
			}
		}
	}
}

.skip-shipment {
	border: 1px solid $color-primary;
	margin-bottom: 20px;

	.header {
		background-color: $color-secondary-accent;
		color: $color-primary-background;
		font: 12px/18px $font-main;
		letter-spacing: 0.4px;
		padding: 4px 18px 4px 16px;
	}

	.content {
		padding: 32px 16px 24px;

		.title {
			font: 24px/40px $font-alt;
			letter-spacing: 0.6px;
			margin-bottom: 24px;
		}

		.undo {
			@include t-link_1;

			background-position: 0 50%;
			color: $color-link;
			font: 16px/24px $font-main;
			letter-spacing: 0.5px;

			@include media(sm) {
				background-position: 0 90%;
			}
		}

		@include media(lg-up) {
			display: flex;
			justify-content: space-between;
			padding: 40px 48px 44px;
		}
	}
}

.b-dialog-window {
	.b-dialog-header {
		.b-dialog-title {
			color: $color-primary;
			font-family: $font-alt;
			font-size: 24px;
			font-weight: 500;
			letter-spacing: 0.6px;
			line-height: 40px;
			text-align: center;
		}
	}
}

.wineclub-modal {
	@include media(md-down) {
		margin: 65px 20px 0 18px;
		overflow-y: auto !important;
	}

	&.m-quick_view {
		&.small-modal {
			.b-dialog-window {
				max-width: 656px;
			}
		}

		.b-dialog-window {
			padding: 32px 30px;

			@include media(lg-up) {
				padding: 40px;
			}

			.b-dialog-header {
				text-align: right;
			}

			.b-dialog-body {
				margin: 0 auto;
				text-align: center;
				width: 80%;

				.skip-count {
					font-size: 16px;
					letter-spacing: 0.15px;
					line-height: 24px;
					margin-bottom: 8px;
				}

				.skip-text {
					font-size: 24px;
					letter-spacing: 0.23px;
					line-height: 32px;
					margin-bottom: 16px;
				}

				.skip-confirm {
					font-size: 16px;
					letter-spacing: 0.15px;
					line-height: 24px;
					margin-bottom: 40px;
				}
			}
		}
	}
}

.manage-equinox {
	img {
		margin-bottom: 64px;
	}

	p {
		font-family: $font-alt;
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;
		text-align: center;

		&.title {
			font-weight: bold;
			margin-bottom: 8px;
		}
	}

	.skipReturn {
		margin: 64px 0 32px;
		max-width: 432px;
	}

	.skip {
		font-family: $font-alt;
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;
		text-transform: uppercase;
	}
}

.edit-milkRun {
	.title {
		font-family: $font-main;
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0.23px;
		line-height: 32px;
		margin-bottom: 32px;
	}

	.prefName {
		font-family: $font-alt;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
		line-height: 24px;
		margin-top: 16px;
	}

	.qty-available {
		display: flex;
		justify-content: center;
		margin: 16px 0;

		.quantity-options {
			margin: 0 5px;

			.radio__label {
				background-color: $color-primary-background;
				border: 1px solid $color-primary;
				border-radius: 30px;
				color: $color-primary;
				cursor: pointer;
				padding: 8px;

				&.selected {
					background-color: $color-primary;
					color: $color-primary-background;
				}
			}
		}
	}

	.price {
		font-family: $font-main;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
		line-height: 24px;
	}

	.disc-price {
		color: $color-shade_4;
		font-family: $font-main;
		font-size: 12px;
		letter-spacing: 0.12px;
		line-height: 24px;
		text-align: center;
	}

	.UpdateMilkRunPrefQty {
		background-color: $color-primary;
		border-color: $color-button_alt-border;
		color: $color-primary-background;
		margin: 16px 0 32px;

		&:hover {
			background-color: $color-primary-background;
			border-color: $color-button_alt__hover-border;
			color: $color-primary;
		}
	}

	.cancel {
		color: $color-link;
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;
		text-align: center;
		text-transform: uppercase;
	}

	.wcproduct-tileimage {
		width: 100%;
	}

	.radio__label svg {
		color: $color-svg-icon;
	}

	.radio__label.selected svg {
		color: $color-primary-background;
	}
}

.cancel-membership-alert {
	.heading {
		font-family: $font-main;
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0.23px;
		line-height: 32px;
		margin-bottom: 16px;
		text-align: left;

		&.success {
			margin-bottom: 40px;
		}
	}

	.desc {
		font-family: $font-alt;
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;
		margin-bottom: 40px;
		text-align: left;

		&.alt {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 16px;
			margin: 56px 0 0;
		}
	}

	.b-form-btn_alt {
		margin-bottom: 16px;

		&.block {
			background-color: $color-button_alt-background;
			border-color: $color-button_alt-border;
			color: $color-button_alt-text;

			&:hover {
				background-color: $color-button_alt__hover-background;
				border-color: $color-button_alt__hover-border;
				color: $color-button_alt__hover-text;
			}

			&.m0 {
				margin: 0;
			}
		}
	}
}

.membership-cancel-reason,
.select-shipment-method {
	border: 2px solid $color-primary;
	color: $color-select-option;
	display: block;
	font-family: $font-main;
	font-size: 16px;
	height: 56px;
	letter-spacing: 0.15px;
	line-height: 24px;
	margin: 24px auto;
	padding: 8px 12px;
	width: 50%;

	@include media(sm) {
		width: 100%;
	}
}

.wcmembership-details {
	margin-top: 10px;
}

.membership-title {
	border-bottom: 1px solid $color-primary;
	color: $color-primary;
	display: flex;
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.23px;
	line-height: 32px;
	margin-bottom: 24px;
	padding: 24px 0 9px;
}

.wcmembership-info {
	border: 1px solid $color-shade_3;
	color: $color-primary;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 4px 0 8px;
	padding: 10px;
}

.wcmembership-manage.wc-hide-mobile {
	@include media(sm) {
		display: none;
	}
}

.wcmembership-title-container {
	display: flex;
	justify-content: space-between;
}

.b-account_info_tile-footer .paymentcardlength-1 {
	display: none;
}

.non-wine-club-member .payment-method .b-account_info_tile-link.m-set_default.wineclub-default,
.non-wine-club-member .payment-method .b-account_info_tile-title.wineclub-default {
	display: none;
}

@include media(md-up) {
	.wc-hide-mobile {
		display: block;
	}

	.wc-visible-mobile {
		display: none;
	}

	.wcmembership-info {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 19px 16px 25px;
	}
}

.wcmembership-product-container {
	display: flex;
	font: 16px/24px $font-alt;
	justify-content: space-between;
	letter-spacing: 0.5px;

	@include media(md-up) {
		width: 60%;
	}

	.wcmembership-manage {
		font: 16px/24px $font-main;
		letter-spacing: 0.5px;
		text-transform: capitalize;
	}
}

.wcmembership-title {
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.15px;
	line-height: 24px;
	padding-bottom: 5px;
}

.wcmembership-productName {
	min-width: 153px;
}

.wcmembership-manage {
	@include t-link_1;

	color: $color-link;
	cursor: pointer;
	font: 16px/24px $font-main;
	padding-left: 10px;
	text-transform: uppercase;

	@include media(md-up) {
		padding: 0;
	}
}

.addwcmembership-cta {
	background: $color-primary-background;
	border: 1px solid $color-secondary-accent;
	color: $color-secondary-accent;
	cursor: pointer;
	font: 16px/24px $configuration-button-font_style;
	height: 56px;
	letter-spacing: 0.5px;
	margin-bottom: 32px;
	padding: 11px 0;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}


.addwcmembership-cta:hover {
	background-color: $color-secondary-accent;
	color: $color-primary-background;
}

.addwcmembership-cta > a {
	display: block;
	padding-top: 5px;
}

.addwcmembership-cta > a::before {
	content: '+';
	padding-right: 3px;
}

.addwcmembership-cta > a:hover {
	text-decoration: none;
}

.wineclub-title {
	color: $color-primary;
	font-size: 32px;
	font-weight: bold;
	letter-spacing: 0.31px;
	line-height: 40px;
}

.nonmember-msg {
	color: $color-primary;
	display: flex;
	font-size: 20px;
	letter-spacing: 0.5px;
	line-height: 32px;
	margin-top: 16px;
}

.b-user_greeting-signout {
	color: $color-link;
}

.l-account-navigation_cta_text {
	color: $color-secondary-accent;
	text-transform: capitalize;
}

.addwcmember-cta {
	background-color: $color-secondary-accent;
	border: 2px solid $color-primary-background;
	color: $color-primary-background;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.15px;
	line-height: 21px;
	margin-top: 57px;
	padding: 16px 27px;
	text-align: center;
	width: 100%;
}

@media screen and (min-width: 480px) {
	.addwcmember-cta {
		width: 432px;
	}
}

.addwcmember-cta a:hover {
	text-decoration: none;
}

.addwcmember-cta a {
	display: block;
	text-transform: uppercase;
}

.modalDialog {
	background: rgba(0, 0, 0, 0.8);
	bottom: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: opacity 400ms ease-in;
	z-index: 99999;
	-webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
}

.modalDialog:target {
	opacity: 1;
	pointer-events: auto;
}

.close {
	background: #606061;
	border-radius: 12px;
	box-shadow: 1px 1px 3px #000;
	color: #ffffff;
	font-weight: bold;
	line-height: 25px;
	position: absolute;
	right: -12px;
	text-align: center;
	text-decoration: none;
	top: -10px;
	width: 24px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-moz-box-shadow: 1px 1px 3px #000;
	-webkit-box-shadow: 1px 1px 3px #000;
}

.close:hover {
	background: #00d9ff;
}

.btn-main:disabled,
.btn-main[disabled] {
	background-color: #cccccc;
	border: 1px solid #b8a9a9;
	color: #666666;
}

.form-modal-container {
	background-color: #ffffff;
	height: 748px;
	margin: 5% auto;
	padding: 5px 20px 13px;
	position: relative;
	width: 1104px;
}

// customize shipment
.prefName {
	font: 700 16px/24px $font-alt;
	letter-spacing: 0.5px;
	padding: 3px;
}

.shipment-newproducts {
	display: contents;

	.saveShipment-cta {
		cursor: pointer;
	}
}

.swap-product-tile,
.added-product-tile {
	border: 1px solid $color-primary;
	min-height: 350px;
	position: relative;
	text-align: center;
	width: 32%;

	@include media(sm) {
		margin: 15px 0;
		min-height: 330px;
		width: 100%;
	}
}

.shipment-products {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: center;

	@include media(sm) {
		display: block;
		width: 100%;
	}

	.radio__input {
		left: 50%;
		opacity: 0;
		position: absolute;
		top: 50%;
	}

	.radio__label {
		background-color: $color-primary-background;
		border: 1px solid $color-primary;
		border-radius: 20px;
		color: $color-primary;
		cursor: pointer;
		display: flex;
		justify-content: center;
		margin: 5px;
		padding: 3px 5px;
		text-align: center;
		transition: 0.25s;
		width: 84px;
	}

	.radio__label span {
		font-size: 16px;
		height: 24px;
		line-height: 24px;
		width: 25px;
	}

	.radio__input:checked + .radio__label {
		background-color: $color-secondary-accent;
		color: $color-primary-background;

		svg {
			color: $color-primary-background;
		}
	}
}

.wcproductQuantity-bottom {
	bottom: 0;
	margin-top: 65px;
	position: absolute;
	width: 100%;

	@include media(sm) {
		margin-top: 20px;
	}

	.b-product_tile-cta {
		border: 0;
		border-top: 1px solid $color-shade_4;
		color: $color-primary;
		display: block;
		font-family: $font-alt;
		width: 100%;

		&::before {
			background: var(--icon-color, $color-svg-icon);
			content: '';
			display: inline-block;
			height: 12px;
			margin-right: 10px;
			mask: url('svg-icons/Swap.svg');
			mask-position: 50%;
			mask-repeat: no-repeat;
			mask-size: 100%;
			width: 12px;
		}

		&.wcproduct-remove::before {
			mask: url('svg-icons/close.svg');
		}

		&:hover {
			&::before {
				background: var(--icon-color, $color-primary-background);
			}
		}
	}
}

.shipment-products .wcproduct-name.prefName {
	display: -webkit-box;
	margin-top: 20px;
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}

.shipment-newproducts,
.shipment-products {
	.b-product_tile,
	.added-product-tile {
		border: solid 1px;
		position: relative;
		width: 32%;

		@include media(sm) {
			margin: 15px 0;
			min-height: 380px;
			width: 100%;
		}
	}

	.b-product_badge-image {
		display: none;
	}

	.b-product_tile-image_wrapper {
		height: 144px;
		margin-bottom: 0;
		margin-top: 8px;
	}

	.b-product_tile-image {
		height: 144px;
		padding-bottom: 0;

		img {
			height: 100%;
			object-fit: contain;
			position: relative;
		}
	}

	.b-product_tile-name {
		font-family: $font-alt;
		font-size: 16px;
		letter-spacing: 0.5px;
		margin-bottom: 0;
		margin-top: 20px;
		overflow: hidden;
		padding: 3px;
		pointer-events: none;
		text-align: center;

		@include lines-clamp-reserve(2, var(--product_tile-name-line_height));

		--product_tile-name-line_height: 24px;
	}

	.b-product_tile-brand {
		display: none;
	}

	.b-product_tile-actions {
		flex-wrap: wrap;
		justify-content: center;
	}

	.b-product_tile-quantity {
		margin-bottom: 16px;
		width: 40%;

		@include media(sm) {
			margin: 0 auto 16px;
		}

		.b-product_quantity {
			height: 52px;
		}
	}

	.b-product_tile-cta {
		background-color: $color-primary-background;
		border: 0;
		border-top: 1px solid $color-shade_4;
		color: $color-primary;
		display: block;
		font-family: $font-alt;
		width: 100%;

		&.removeProdFromSession::before {
			background: var(--icon-color, $color-svg-icon);
			content: '';
			display: inline-block;
			height: 10px;
			mask: url('svg-icons/close.svg');
			mask-position: 50%;
			mask-repeat: no-repeat;
			margin-right: 10px;
			mask-size: 100%;
			width: 11px;
		}

		&:hover {
			background-color: $color-button_main-background;
			color: $color-button_main-text;

			&:before {
				background: var(--icon-color, $color-primary-background);
			}
		}
	}

	.b-product_tile-description {
		display: none;
	}

	.b-product_tile .b-add_to_wishlist {
		display: none;
	}

	.b-product_tile-promotion {
		display: none;
	}

	.b-product_tile-availability {
		display: none;
	}

	.b-product_badge.m-rating_points,
	.b-product_badge.m-promo,
	.b-product_badge-image {
		display: none !important;
	}
}

.address-book,
.payment-method {
	.b-account_info_tile {
		&-footer {
			display: block;
		}

		&-content {
			@include media(sm) {
				text-align: right;
			}
		}

		&-title {
			display: inline-block;
			font-size: 16px;
			font-weight: 600;
			margin-right: auto;
		}

		&-link.m-edit {
			float: right;
			text-transform: uppercase;

			@include media(sm) {
				float: none;
			}
		}

		&-link.m-delete {
			float: right;
			margin-right: rh(2);
			text-transform: uppercase;

			@include media(sm) {
				float: none;
			}
		}

		&-link.m-set_default {
			display: table;
		}

		.b-payment_icon {
			display: none;
		}
	}
}

.payment-method {
	.b-account_info_tile {
		&-link.m-set_default {
			display: inline-block;
		}

		&-link.m-delete {
			@include media(sm) {
				float: right !important;
			}
		}

		&-footer > a:first-child {
			display: table !important;
		}
	}
}

.payment-details {
	.b-account_card {
		&-main {
			display: flex;

			@include media(sm) {
				display: block;
			}
		}

		&-padding-right {
			padding-right: 15%;
		}
	}
}

a.b-account_card-button,
.override-batch-button {
	border: 1px solid;
	height: 48px;
	padding: 5px;
	width: 296px;
}

.b-account_card-button.override-batch-button {
	font-weight: 400;
}

.b-account_card-link {
	color: var(--color-link, $color-secondary-accent) !important;
	text-transform: uppercase;
}

.address-book {
	.pickupatwinery-title {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
		line-height: 24px;
		margin-bottom: 15px;
	}

	.b-account_info_tile-link.m-set_default {
		font-size: 12px;
		line-height: 24px;
		text-transform: uppercase;
	}

	.b-account_info_tile-title {
		font-family: $font-alt;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
		line-height: 24px;
	}
}

.custom-shipment {
	.qty-available {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		margin: 16px 0 24px;
	}

	.quantity-options {
		.radio__label {
			width: 75px;
		}
	}
}

.ground-description {
	padding-bottom: 8px;
}

.f-input_text-label {
	font-size: 16px;
	font-weight: 200;
}

fieldset {
	&.b-address_selector {
		&.b-form-set {
			margin-top: 50px;
		}
	}
}

.l-account {
	.b-address_selector-back {
		font-family: $font-main;
		font-size: 16px;
		margin-bottom: 32px;
		text-transform: uppercase;
	}
}

.b-account_card {
	&-default_wineclub {
		display: flex;

		@include media(md-down) {
			display: block;
			padding-bottom: 30px;
		}
	}

	&-spaceaddress {
		padding-right: 10px;
	}

	&-defaultaddress {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
		padding-top: 13px;

		@include media(md-down) {
			padding-top: 8px;
			width: max-content;
		}

		svg {
			height: 12px;
			width: 17px;
		}
	}

	&-pickwinery {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
		padding-top: 10px;

		@include media(md-down) {
			padding-bottom: 8px;
			padding-top: 0;
		}
	}

	&-fullName {
		padding-top: 10px;

		@include media(md-down) {
			padding-top: 0;
		}
	}

	&-button {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
	}

	&-title {
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0.23px;
	}

	&-bottomspaceaddress {
		font-size: 16px;
		margin: 10px 0 30px;
	}

	&-wine-body {
		font-size: 16px;
		padding-bottom: 20px;

		@include media(md-down) {
			margin: 10px 0 30px;
			padding-bottom: 0;
		}
	}

	&-billing-body {
		font-size: 16px;
		margin: 0;
	}
}

.b-account_info_tile .b-account_info_tile-content {
	position: relative;

	.nonCompliantState_state_mesg {
		font-size: 12px;
		line-height: 16px;
		max-width: 190px;
		position: absolute;
		text-align: left;

		@include media(sm) {
			display: flex;
			max-width: 54%;
		}
	}
}

.add-bottles-to-Shipment-container {
	.b-carousel-title {
		font-family: $font-alt !important;
	}

	.b-product_tile-name {
		color: $color-secondary-accent;
		font: 16px/24px $font-alt;
		letter-spacing: 0.5px;
	}

	.b-product_badge.m-rating_points,
	.b-product_badge.m-promo,
	.b-product_badge-image {
		display: none !important;
	}
}

.swap-bottle-dialog-body {
	background-color: $color-primary-background;

	.swap-dialog-inner {
		display: flex;
		justify-content: center;
		margin: auto;
		text-align: center;

		.mainContentPD {
			.b-carousel-track {
				margin: 0 60px;
			}

			.b-product_tile-actions {
				margin-top: auto;
				width: 100%;
			}

			.b-carousel-control.m-previous {
				left: 25px;
				position: absolute;
				top: 35%;
			}

			.b-carousel-control.m-next {
				position: absolute;
				right: 25px;
				top: 35%;
			}

			.b-product_tile-actions.m-restriction {
				display: none;
			}
		}

		.b-product_tile.m-grid.m-page_designer.m-static {
			display: flex;
			flex-direction: column;
		}
	}
}

.swap-dialog-inner {
	padding-bottom: 30px;

	.b-carousel-title {
		font-family: $font-alt !important;
	}

	.b-product_badge.m-rating_points,
	.b-product_badge.m-promo,
	.b-product_badge-image {
		display: none !important;
	}
}

.swap-product-tile {
	&.has-remove-button {
		min-height: 400px;

		@include media(sm) {
			min-height: 380px;
		}

		.wcproductQuantity-bottom {
			.wcproduct-remove::before {
				display: inline-block;
				height: 10px;
				mask: url('svg-icons/close.svg');
				width: 11px;
			}
		}
	}
}

.edit-shipment-method-modal {
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 50%;

	@include media(md-down) {
		margin: auto;
	}

	@include media(sm) {
		margin: 65px 20px 18px;
		width: initial;
	}
}

.select-shipment-method {
	color: $color-select-option;
	width: 81%;
}

.b-track_notification {
	&-title {
		font: 400 16px/24px $font-alt;
		letter-spacing: 0.5px;
		text-transform: capitalize;
	}

	&-inner {
		background-color: $color-track-notification;
		border: 2px solid $color-shade_8;
		font: 400 16px/24px $font-main;
		letter-spacing: 0.5px;
	}

	&-link {
		color: $color-secondary-accent;
		font: 400 16px/24px $font-main;
		letter-spacing: 0.5px;
		text-transform: capitalize;
	}
}

.process-order-section {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 24px;
	width: 100%;

	.process-order-button {
		font-weight: 400;
	}
}
