@import 'common-flagship_tasting_room';

.Customizeshipment-heading {
	font-size: 32px;
	font-weight: bold;
	letter-spacing: 0.31px;
	line-height: 40px;
	margin: 5px 0 23px;
}

.customizeShipment-title {
	border-bottom: 1px solid;
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.23px;
	line-height: 32px;
	padding-bottom: 9px;
}

.shipment-details {
	border-bottom: 1px solid;
	display: flex;
	flex-direction: column;
	padding-bottom: 32px;
	padding-top: 40px;

	@include media(md-up) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.wineshipment-priceinfo {
		display: flex;
		flex-direction: column;
		font-family: $font-main;
		font-size: 16px;
		justify-content: space-between;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-up) {
			justify-content: flex-start;
			width: 80%;
		}

		span {
			margin-right: 32px;
			padding-left: 29px;
		}

		.wineshipment-error,
		.shipcompliant-error {
			padding: 0;

			.errorMesg {
				color: $color-error;
				font-size: 16px;
				line-height: 20px;
				padding: 8px 0;
			}
		}

		.customization-info {
			margin-top: 8px;
		}
	}

	.minshipment-priceinfo {
		font-family: $font-alt;
		font-size: 12px;
		letter-spacing: 0.12px;
		line-height: 16px;
	}

	.saveShipment-cta {
		background-color: $color-secondary-accent;
		color: $color-primary-background;
		cursor: pointer;
		font-family: $font-alt;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
		line-height: 21px;
		margin: 16px auto 25px;
		padding: 16px 20px;
		text-align: center;
		text-transform: uppercase;
		width: 248px;

		@include media(md-up) {
			margin: 0;
			width: 320px;
		}
	}
}

.shipment-products {
	display: flex;
	flex-wrap: wrap;

	.wcproductQuantity-tile {
		flex: 100%;
		max-width: 320px;
		min-height: 353px;

		@include media(md-up) {
			flex: 0 0 25%;
			min-width: 274px;
		}

		img {
			height: 144px;
		}

		.wcproductprice {
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0.15px;
			line-height: 24px;
			margin: 8px;
		}

		.b-product_tile-cta {
			border: 0;
			border-top: 1px solid $color-primary-background;
			color: $color-primary-background;
			margin-top: auto;
			width: 100%;
		}
	}
}

.shipment-newproducts {
	.wcproductQuantity-tile {
		background-color: $color-primary-background;
		border: 1px solid $color-primary;
		color: $color-primary;
	}
}

.customizeShipment-msg {
	display: flex;
	flex-direction: column;
	margin: 25px 0;
	text-align: center;

	@include media(md-up) {
		flex-direction: row;
		justify-content: center;

		span {
			padding-top: 0;
		}
	}

	span {
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;
		padding-left: 8px;
		padding-top: 16px;
		text-decoration: underline;
		text-transform: uppercase;
	}
}

.mainContentPD {
	.b-carousel-title_container {
		margin-top: 56px;

		.b-carousel-title {
			font-family: $font-main;
			font-size: 24px;
			font-weight: bolder;
		}
	}

	.b-carousel-control {
		border: 0;

		&:hover {
			box-shadow: none;
		}
	}

	.b-product_tile-image {
		background: transparent;
	}

	.b-product_tile-actions {
		flex-direction: column;
	}

	.b-add_to_wishlist {
		display: none;
	}

	.b-product_tile-quantity {
		width: 100%;

		@include media(md-up) {
			width: 100%;
		}
	}

	.b-product_tile-cta {
		background-color: $color-primary-background;
		color: $color-primary;
		width: 100%;
	}

	.b-product_quantity {
		border: 2px solid $color-primary;
		margin: 8px 0;

		@include media(md-up) {
			border: 2px solid $color-primary;
			margin: 8px 0;
		}
	}

	.b-product_tile.m-grid .b-product_quantity {
		border: 2px solid $color-primary;

		@include media(md-up) {
			border: 2px solid $color-primary;
		}
	}
}

.wineshipmentSwap-error,
.override-batch-sc-error {
	text-align: center;

	.errorMesg {
		color: $color-error;
	}
}

.wineshipment-override-error {
	text-align: center;

	.errorMesg {
		color: $color-error;
	}
}

.override-batch_dialog-header {
	align-items: baseline;
	background-color: $color-primary-background;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;

	.b-search_dialog-form_wrap {
		margin-bottom: 0;
	}

	.b-search_dialog-inner {
		padding: 32px;
		width: 80%;

		.m-no_results {
			padding: 32px 0;
		}
	}
}

.override-batch-dialog-body {
	background-color: $color-primary-background;
	padding-bottom: 110px;
	padding-top: 150px;
}

.override-batch-dialog-inner {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	.override-batch_product {
		align-items: flex-start;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding: 16px;
		width: 82%;

		@include media(sm) {
			.override-batch_product_details-name {
				width: 60%;
			}
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid $color-shade_4;
		}

		.override-batch_product-image {
			margin-right: 48px;
			max-width: 87px;

			@include media(sm) {
				margin-right: 16px;
			}
		}

		.override-batch_product-quantity {
			margin-left: auto;
			padding: 8px;

			@include media(sm) {
				margin-left: 32%;
			}

			.override-product_quantity-label {
				font-size: 14px;
				margin-right: 8px;
			}

			.override-product_quantity-input {
				border: 1px solid $color-shade_4;
				font-size: 20px;
				font-weight: 400;
				line-height: 32px;
				text-align: center;
			}
		}
	}
}

.override-batch-modal_footer.b-dialog-footer {
	background-color: $color-primary-background;
	border-top: 1px solid $color-shade_4;
	bottom: 0;
	display: flex;
	justify-content: space-around;
	margin-top: 60px;
	position: fixed;
	width: 100%;

	.b-form-btn_main,
	.b-form-btn_alt {
		margin: 25px 0;
		width: 20%;

		@include media(sm) {
			margin: 16px 0;
			width: 35%;
		}
	}

	.b-form-btn_alt {
		border: 1px solid $color-button_alt-border;
	}
}
