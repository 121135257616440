@import 'account-flagship_tasting_room.scss';
@import 'account-wineclub.scss';

.your-shipment .shipment-card .shipment-type{
    background: $color-secondary-accent;
    font-family: $font-alt;
    &.confirm{
        background-color: $color-shade_4; 
        color: $color-primary;
    }
}
.b-tab_list-tab.m-active{
    border-bottom: 3px solid $color-secondary-accent;
}

.b-account_card {
	&-spaceaddress {
        letter-spacing: -0.2px;
	}

	&-defaultaddress {
		letter-spacing: -0.2px;
	}

	&-pickwinery {
		letter-spacing: -0.2px;
	}

	&-fullName {
		@include media(md-down) {
			padding-bottom: 8px;
		}
	}

	&-button {
		font-size: $configuration-button-font_size;
		font-weight: $configuration-button-font_weight;
		letter-spacing: $configuration-button-letter_spacing;
        font-style: $configuration-button-font_style;
	}

	&-title {
        font-family: $font-alt;
		font-weight: 500;
		letter-spacing: 0.5px;
	}
}

.b-account_card-link{
    color: var(--color-link, $color-link) !important;
    font-weight: 400;
}

.b-account_nav-list{
    .b-account_nav-item {
        font-family: $font-alt;
        font-weight: 500;
        letter-spacing: 0.8px;
    }
}
